@import "../../vars.scss";

.container {
  height: 100vh;
  width: 260px;
  background-color: #ffffff;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 75px;
  height: 75px;
  flex-direction: column;
}
.logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 70px;
}
.othrText {
  z-index: 2;
  width: 47px;
  height: 18px;
  margin-top: 4px;
}
.departementText {
  color: $white;
  opacity: 0.4;
  font-size: 20px;
  font-weight: bold;
  z-index: 2;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 2px;
}

.list {
  padding: 0 40px;
  overflow-y: scroll;
}

.menuItem {
  height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow-wrap: break-word;
}

.menuText {
  color: $grey;
  text-decoration: none;
  font-size: 0.9em;
  cursor: pointer;
  text-align: left;
  overflow-wrap: break-word;
  margin-left: 15px;
  max-width: 110px;
}
.menuTextActive {
  font-weight: 600;
}

.header {
  display: flex;
  width: 100%;
  padding: 25px;
}

.headerTitleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 75px;
  margin-left: 10px;
  max-width: 120px;
}

.headerTitle {
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 5px;
}
.headerSubtitle {
  display: block;
  font-weight: 600;
  font-size: 0.7em;
  line-height: 1em;
  max-height: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.notificationContainer {
  background-color: $burgundy;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}
.notification {
  color: $white;
  font-size: 12px;
  line-height: 12px;
  border-radius: 20px;
  margin: 0;
  margin-block-end: 0;
}
.disabledLink {
  pointer-events: none;
  opacity: 0.6;
}

.icon {
  height: 30px;
  width: 30px;
}
