@import "../../vars.scss";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
}

.filters {
  margin: 15px 50px 10px 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filtersTitle {
  margin-right: 20px;
}
.filter {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.select {
  z-index: 3;
  margin: 25px;
  width: 250px;
}

.stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 25px;
  justify-content: space-between;
}
.statContainer {
  flex: .32;
  background-color: white;
  border-radius: 5px;
  padding: 15px;
}
.statName {
  font-size: 1.1rem;
  margin-bottom: .8rem;
}
.statValue {
  color: $burgundy;
  font-size: 2.5rem;
}
.statGreen {
  color: $green;
}
.cityGroups {
  margin-top: 25px;
  display: flex;
  width: 100%;
  padding: 25px;
  flex-direction: row;
}
.cityGroupsTitle {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}
.leftCityGroup {
  margin-right: 50px;
}
.rightCityGroup {
  display: flex;
  flex-direction: row;
}
.cgStats {

}
.cgStatContainer {
  background-color: white;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: .5rem;
  width: 200px;
}
.label {
  font-size: .6rem;
}
