@import "../../vars.scss";

.overlay {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  //height: 600px;
  width: 750px;
  border-radius: 15px;
  overflow: hidden;
}

.header {
  width: 100%;
  height: 120px;
  background-color: #DBDBDB;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-left: 80px;
  box-sizing: border-box;
}

.fullname {
  font-size: 1.5em;
  font-weight: bold;
}
.picture {
  height: 80px;
  width: 80px;
  border-radius: 90px;
  overflow: hidden;
  margin-right: 20px;
  object-fit: cover;
}

.closeIcon {
  position: absolute;
  right: 50px;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.content {
  display: flex;
  width: 80%;
  height: 100%;
  padding-top: 20px;
  margin-bottom: 70px;
}
.column {
  width: 50%;
  height: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

.title {
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;
}
.data {
  margin-top: 10px;
}
.email {
  cursor: pointer;
  color: $link;
}
