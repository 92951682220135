$white: #ffffff;
$background: #f2f2f2;
$light_background: #f7f7f7;
$dark_background: #ebebeb;
$grey: #141414;
$burgundy: #BC1E2C;
$yellow: #FAA60E;
$green: #28CB80;
$light_green: #58FBA077;
$link: #008AD1;
