@import "../../vars.scss";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
}

.filters {
  margin: 15px 50px 10px 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filtersTitle {
  margin-right: 20px;
}
.filter {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.select {
  z-index: 3;
  margin-left: 10px;
  margin-right: 20px;
  width: 180px;
}
