@import "../../vars.scss";

.container {
  flex: 1;
  width: calc(100vw - 260px);
  padding: 0 25px 25px 0;
  margin-top: 15px;
  overflow: scroll;
  box-sizing: border-box;
}

table {
  margin-left: 25px;
  background: $white;
  border-radius: 5px;
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.2);
  border-spacing: 0;
  border-collapse: collapse;
  position: relative;
  overflow-y: scroll;
  overflow-x: scroll;
}

thead {
  position: relative;
  z-index: 2;
  background-color: $white;
}

th:first-child {
  border-top-left-radius: 5px;
}
th:last-child {
  border-top-right-radius: 5px;
}
th {
  background-color: $white;
  position: sticky;
  top: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
}
th:first-child, td:first-child {
  padding-left: 20px;
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: $light_background;
}
th:nth-child(2), td:nth-child(2) {
  padding-left: 10px;
}
td:last-child {
  padding-right: 20px;
}
.row:nth-child(odd) {
  background: $light_background;
}

td {
  font-size: 0.9em;
  vertical-align: center;
  height: 70px;
  max-width: 230px;
  padding: 0 5px;
}

.since {
  font-weight: bold;
}

.danger {
  color: $burgundy;
}

.warning {
  color: $yellow;
}

.valid {
  color: $green;
}

.button {
  //background-color: $light_green;
  //border: 2px solid $grey;
  //height: 30px;
  //width: 160px;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //border-radius: 20px;
  color: $grey;
  cursor: pointer;
  font-size: 0.9em;
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-color: $grey;
}
.button:hover {
  //border: 2px solid $grey;
  opacity: 0.5;
  text-decoration-color: rgba($grey, 0.5);
}

.userContainer {
  display: flex;
  align-items: center;
}
.userPicture {
  height: 55px;
  width: 55px;
  border-radius: 55px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  object-fit: cover;
}
.userName {
  width: 180px;
  margin-left: 20px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 5px;
}

.selectContainer {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.select {
  width: 150px;
}

.networksContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 110px;
}
.networksLogo {
  height: 55px;
  width: 55px;
  border-radius: 55px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  object-fit: cover;
}
.noNetworks {
  width: 55px;
  text-align: center;
  font-style: italic;
}

.cardContainer {
  width: 50px;
}
.cardIcon {
  width: 27px;
  height: 30px;
}
.cardIcon:hover {
  cursor: pointer;
}

.eventContainer {
  display: flex;
  align-items: center;
}
.eventPicture {
  height: 55px;
  width: 55px;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  object-fit: cover;
}
.eventTitle {
  margin-left: 20px;
  font-weight: bold;
  max-width: 160px;
}

.date {
}

.participationContainer {
  display: flex;
  align-items: center;
  font-size: 1.3em;
}
.participationTaken {
  font-weight: bold;
}
.participationTotal {
  font-weight: normal;
}

.rate {
  font-weight: bold;
  font-size: 1.1em;
}

.networkContainer {
  display: flex;
  align-items: center;
}
.networkPicture {
  height: 55px;
  width: 55px;
  border-radius: 55px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  object-fit: cover;
}
.networkName {
  margin-left: 20px;
  font-weight: bold;
  width: 150px;
}

.numberContainer {
  width: 60px;
}
.number {
  font-style: italic;
}

.followTable {
  min-width: 80px;
  max-width: 230px;
}
.followTableImportant {
  color: $yellow
}
.followTableTotal {
  font-weight: bold;
  color: $burgundy;
}
.subtitle {
  font-style: italic;
  font-size: .9rem;
}
