@import "../../vars.scss";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
}

.showAll {
  margin-left: 20px;
}

.label {
  display: flex;
  align-items: center;
  margin: 10px;
}
