@import "../../vars.scss";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
}
.content {
  padding: 25px;
}

.title {
  font-size: 1.4rem;
  margin-bottom: 10px;
}
.codesContainer {
}

.codesMaker {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 300px;
}

.button {
  background-color: $white;
  border-radius: 5px;
  box-shadow: 2px 2px 8px rgba($grey, 0.2);
  padding: 10px;
  margin-top: 10px;
}
.button:hover {
  cursor: pointer;
}

.quota {
  margin-top: 10px;
}

.billingContainer {
  display: flex;
  flex-direction: column;
  width: 300px;
}
