@import "../../vars.scss";

.container {
  height: 120px;
  width: 100%;
  background-color: $background;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.shadow {
  position: absolute;
  bottom: 0;
  height: 10px;
  width: 100%;
  background: linear-gradient(#70707018, $background);
}

.title {
  font-weight: 600;
  font-size: 1.3em;
  margin-left: 50px;
}

.userContainer {
  height: 48px;
  width: 48px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  margin-right: 50px;
}
.userImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.userContainer:hover {
  cursor: pointer;
}
