.container {
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 1.5em;
  font-width: bold;
}

.subtitle {
  font-size: 1em;
  font-weight: normal;
  margin-top: 20px;
}

.error {
  font-style: italic;
  margin-top: 40px;
}
