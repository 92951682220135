@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import "./vars.scss";

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
  font-family: 'Lato', Arial, sans-serif;
  -ms-overflow-style: none;
  scrollbar-width: 0;
  color: $grey;
}
*::-webkit-scrollbar {
  display: none;
}
