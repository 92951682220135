.container {
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100%;
  background-color: #f2f2f2;
}

.content {
  flex: 1;
  height: 100vh;
}
