@import "../../vars.scss";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
}

.select {
  z-index: 3;
  margin-left: 25px;
  width: 200px;
}
