@import "../../vars.scss";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
}
.content {
  display: flex;
  flex-direction: row;
  padding: 25px;
}

.title {
  font-size: 1.4rem;
}
.codesContainer {
}

.codesMaker {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  //width: 300px;
}

.button {
  display: inline-block;
  background-color: $white;
  border-radius: 5px;
  box-shadow: 2px 2px 8px rgba($grey, 0.2);
  padding: 10px;
  margin-top: 10px;
}
.button:hover {
  cursor: pointer;
}

.quota {
  margin-top: 10px;
}

.billingContainer {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  width: 300px;
}
.spacer {
  height: 5px;
}

.codesText {
  margin-left: 100px;
  background-color: $white;
  border-radius: 5px;
  width: 300px;
  max-height: 200px;
  padding: 10px;
  box-shadow: 4px 4px 5px 2px rgba(200,200,200,.5);
  overflow-y: scroll;
}
